import { apiKernel } from "services/api"

export const getGeneralData = async () => {
	try {
		const generalRulesData = await apiKernel.get(`/ProgramRules/General/BasicData`)


		if (generalRulesData?.status === 200 && generalRulesData?.data) {
			const { data } = generalRulesData
			localStorage.setItem('programName',  data?.name)

		}
	} catch (error) {
		console.log('error', error)
	}
}
