import 'react-app-polyfill/stable'
import React, { useEffect } from 'react'
import Moment from 'react-moment'
import ReactDOM from 'react-dom'
import moment from 'moment/min/moment-with-locales'
import momentTimezone from 'moment-timezone'
import { ThemeProvider } from 'styled-components'
import history from 'services/history'
import { QueryClientProvider, QueryClient } from 'react-query'
const queryClient = new QueryClient()
//
import CustomTheme from 'assets/theme/CustomTheme'
import 'assets/icons/style.css'

import 'moment/locale/pt-br'
import 'moment/locale/es'

import 'utils/i18n'

import App from './App'
import { CVALE, themeColor } from 'store/api/Theme'
import Favicon from 'react-favicon'
import { envs } from 'store/windowServerData'

Moment.globalMoment = moment

// Set the locale for every react-moment instance to French.
if (localStorage.getItem('i18nextLng') !== null) {
	Moment.globalLocale = localStorage.getItem('i18nextLng').toLowerCase()
} else {
	Moment.globalLocale = 'pt-BR'
}

// Set the output format for every react-moment instance.
Moment.globalFormat = 'DD MM YYYY'

// Set the timezone for every instance.
Moment.globalTimezone = 'America/Sao_Paulo'

// Use a <span> tag for every react-moment instance.
Moment.globalElement = 'span'

// Upper case all rendered dates.
Moment.globalFilter = (d) => {
	return d.toUpperCase()
}

momentTimezone().tz('America/Sao_Paulo').format()

/* It needs to find a better way as soon as possible */
window.onbeforeunload = function () {
	if (localStorage.getItem('i18next_pt-BR-common') !== null) {
		localStorage.removeItem('i18next_pt-BR-common')
	}
	if (localStorage.getItem('i18next_es-common') !== null) {
		localStorage.removeItem('i18next_es-common')
	}
	if (localStorage.getItem('i18next_en-common') !== null) {
		localStorage.removeItem('i18next_en-common')
	}
}

const AppWrapper = () => {
	console.log('AppWrapper')
	const [color, setColor] = React.useState('')

	useEffect(() => {
		setColor(JSON.parse(localStorage.getItem('themePdv')))
	}, [])

	useEffect(() => {
		if (history.location.pathname !== '/signin') {
			if (`${envs.REACT_APP_CVALE_IDENTITY}` !== 'true') {
				!localStorage.getItem('themePdv') && (localStorage.clear(), history.push('/signin'))
			}
		}
	}, [])

	return (
		<>
			<Favicon
				url={
					envs.REACT_APP_CVALE_IDENTITY === 'true'
						? CVALE?.images?.favicon?.url
						: JSON.parse(localStorage.getItem('themePdv'))?.images?.favicon?.url ||
						  color?.images?.favicon?.url
				}
			/>
			<App />
		</>
	)
}

ReactDOM.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<ThemeProvider theme={CustomTheme}>
				<AppWrapper />
			</ThemeProvider>
		</QueryClientProvider>
	</React.StrictMode>,
	document.getElementById('root'),
)
