import produce from 'immer';

const INITIAL_STATE = {
	open: false,
};

export default function auth(state = INITIAL_STATE, action) {
	return produce(state, (draft) => {
		switch (action.type) {
		case '@app/OPEN_DRAWER': {
			draft.open = true;
			break;
		}
		case '@app/CLOSE_DRAWER': {
			draft.open = false;
			break;
		}

		default:
		}
	});
}
