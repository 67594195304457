import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Router, Route, Switch } from 'react-router-dom'
import axios from 'axios'

import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import PageContextProvider from 'store/contexts/ContextData'

import { store, persistor } from 'store'
import GlobalStyle from 'assets/global'

import history from 'services/history'
import Loader from 'components/Loader'
import { envs } from 'store/windowServerData'

const DefaultLayout = lazy(() => import('pages/Admin'))

const SignIn = lazy(() => import('pages/Auth/SignIn'))
const PDVs = lazy(() => import('pages/Auth/PDVs'))
const ResetPWD = lazy(() => import('pages/Auth/ResetPWD'))
const ConfirmToken = lazy(() => import('pages/Auth/ConfirmToken'))
const NewPassword = lazy(() => import('pages/Auth/NewPassword'))

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
axios.defaults.headers.common['appId'] = 'PDV1805'

// eslint-disable-next-line no-undef
// axios.defaults.baseURL = envs.REACT_APP_GERMINI_API_URL
axios.defaults.baseURL = envs.REACT_APP_GERMINI_API_URL

function App() {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<PageContextProvider>
					<Suspense fallback={<Loader />}>
						<Router history={history}>
							<Switch>
								<Route
									exact
									path="/signin"
									name="Login Page"
									render={(props) => <SignIn {...props} />}
								/>
								<Route
									exact
									path="/pdvs"
									name="Seleionar PDV"
									render={(props) => <PDVs {...props} />}
								/>
								<Route
									exact
									path="/resetPassword"
									name="Redefinir senha"
									render={(props) => <ResetPWD {...props} />}
								/>
								<Route
									exact
									path="/confirmToken"
									name="Confirm Token"
									render={(props) => <ConfirmToken {...props} />}
								/>
								<Route
									exact
									path="/newPassword"
									name="New Password"
									render={(props) => <NewPassword {...props} />}
								/>
								<Route
									path="/"
									name="Home"
									render={(props) => <DefaultLayout {...props} />}
								/>
							</Switch>
						</Router>
					</Suspense>
					<GlobalStyle />
					<ToastContainer
						autoClose={false}
						closeOnClick
						hideProgressBar={false}
						newestOnTop={false}
						pauseOnFocusLoss
						pauseOnHover
						position="top-right"
						rtl={false}
						theme={'colored'}
					/>
				</PageContextProvider>
			</PersistGate>
		</Provider>
	)
}

export default App
