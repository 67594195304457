import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

export default (reducers) => {
	const persisteReducer = persistReducer(
		{
			key: 'germiniPDV',
			storage,
			whitelist: ['auth'],
		},
		reducers
	);

	return persisteReducer;
};
