import styled from 'styled-components/macro';
import { Dialog } from '@material-ui/core';

import theme from 'assets/theme/CustomTheme';

export const DialogContainer = styled(Dialog)`
	.MuiDialog-paperWidthSm {
		border-radius: 26px;
		padding: 36px;
	}

	.modal-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		color: ${theme.warning};

		.modal-box-icon {
			text-align: center;
			font-size: 65px;

			margin-bottom: 30px;
		}

		.modal-box-title {
			text-align: center;
			font-size: 18px;
			font-weight: bold;
		}
	}
`;
