import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Slide, DialogContent, Grid } from '@material-ui/core';
import { DialogContainer } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

function Loader({ active = false }) {
	return (
		<DialogContainer
			TransitionComponent={Transition}
			aria-describedby="loader-description"
			aria-labelledby="loader-title"
			fullWidth
			keepMounted
			maxWidth={'sm'}
			open={active}
		>
			<DialogContent>
				<Grid container className="modal-box">

					<Grid item xs={12} className="modal-box-icon">
						<div className={'icon-processando'} />
					</Grid>
					<Grid item xs={12} className="modal-box-title"
					>
						<div>PROCESSANDO</div>
					</Grid>
				</Grid>
			</DialogContent>
		</DialogContainer>
	);
}

Loader.propTypes = {
	active: PropTypes.bool
};

export default Loader;
