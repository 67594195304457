// GET
export const GET_REPORT_FREQUENCY = '/PDV/GetReportFrequency'

// GET
export const GET_PDV_STATUS = '/PDV/GetPDVStatus'

// POST
// Cadastra e/ou atualiza PDV
export const POST_PDV = '/PDV/PostPDV'

// GET
// Retorna dados do PDV
export const GET_PDV = '/PDV/GetPDV'

// GET
// Retorna dados do PDV
export const VALIDATE_PARTNER = '/Partner/ValidatePartner'

// GET
// Retorna a lista de pdvs do parceiro
export const GET_PDVS = '/PDV/GetPDVs'

// GET
// Retorna o usuário logado no pdv
export const GET_ACTIVE_PDV_USER = '/PDV/GetActivePDVUser'

// GET
// Desconectar o usuário logado no momento
export const DISCONECT_USER = '/PDV/DisconectUser'

// POST
// Valida permissão do usuario para pdv
export const VALIDATE_LOGIN_PDV = '/PDV/ValidateLoginPDV'

// GET
export const GET_PDV_BY_PARTNER = '/PDV/GetPDVByPartner'

// POST
export const LOGIN_PDV = '/PDV/LoginPDV'

// GET
// Login do usuário do PDV
export const LOGOUT_PDV = '/PDV/LogoutPDV'

// GET
// Valida se CPF possui cadastro ativo no programa de fidelidade
export const VALIDATE_CONSUMER_BY_CPF = '/PDV/ValidateConsumerByCpf'

// POST
export const CREATE_RECEIVEMENT_QR_CODE = '/PDV/CreateReceivementQRCode'

// POST
export const REVERSE_PDV_OPERATION = 'PDV/ReversePDVOperation/'

// POST
export const CHECK_PDV_RECEIVEMENT = '/PDV/CheckPDVReceivement'

// GET
export const SEND_RESET_PASSWORD_REQUEST = '/PDV/SendResetPasswordRequest/'

// GET
export const GET_PDV_REPORT = '/PDV/GetPDVReport'

// GET
export const GET_PDV_REPORT_ITEM_DETAIL = '/PDV/GetPDVReportItemDetail/'

// GET
// Retorna a lista de origens de crédito cadastradas para o PDV
export const GET_CREDIT_ORIGIN_BY_PDV = '/PDV/GetCreditOriginsByPDV'

// GET
export const GET_PAYMENT_FORMS_BY_PDV = '/PDV/GetPaymentFormsByPDV'

// POST
export const CREATE_CREDIT_QR_CODE = '/PDV/CreateCreditQRCode'

// POST
export const VALIDATE_PDV_RECEIVEMENT = '/PDV/ValidatePDVReceivement'

// POST
export const UPDATE_PDV_RECEIVEMENT_INVOICE = '/PDV/UpdatePDVReceivementInvoice'

// POST
export const GET_PDV_OPERATION = '/PDV/GetPDVOperation'

// GET
export const VALIDATE_CONSUMER_BY_CODE = '/Consumer/ValidateConsumerByCode'

// GET
export const GET_PDV_USER = '/PDV/GetPDVUser'

// GET
export const GET_PDV_PARTNER = '/PDV/GetPDVPartner'

// POST
export const CHANGE_PASSWORD = '/PDV/ChangePassword'

// POST
export const UPDATE_QRCODE_STATUS = '/PDV/UpdateQRCodeStatus'
