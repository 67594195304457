export const SIGN_IN_REQUEST = '@auth/SIGN_IN_REQUEST';
export const SELECT_PDV = '@auth/SELECT_PDV';
export const SIGN_IN_SUCCESS = '@auth/SIGN_IN_SUCCESS';
export const SIGN_UP_REQUEST = '@auth/SIGN_UP_REQUEST';
export const SIGN_OUT_REQUEST = '@auth/SIGN_OUT_REQUEST';
export const SIGN_IN_FAILURE = '@auth/SIGN_IN_FAILURE';
export const SIGN_OUT = '@auth/SIGN_OUT';
export const REQUEST_PASSWORD = '@auth/REQUEST_PASSWORD';
export const REQUEST_PASSWORD_SUCCESS = '@auth/REQUEST_PASSWORD_SUCCESS';
export const RESET_PASSWORD = '@auth/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = '@auth/RESET_PASSWORD_SUCCESS';
export const SIGN_IN_TOKEN = '@auth/SIGN_IN_TOKEN';

export function signToken(token) {
	return {
		type: SIGN_IN_TOKEN,
		payload: { auth: { token } },
	};
}

export function signInRequest(cpf, password) {
	return {
		type: SIGN_IN_REQUEST,
		payload: { cpf, password },
	};
}

export function selectPDV(pdv) {
	return {
		type: SELECT_PDV,
		payload: { pdv },
	};
}

export function signInSuccess(user, token, pdVs, partners) {
	return {
		type: SIGN_IN_SUCCESS,
		payload: { user, token, pdVs, partners },
	};
}

export function signUpRequest(name, email, password) {
	return {
		type: SIGN_UP_REQUEST,
		payload: { name, email, password },
	};
}

export function signOutRequest() {
	return {
		type: SIGN_OUT_REQUEST,
	};
}
export function signFailure(errorMsg) {
	return {
		type: SIGN_IN_FAILURE,
		payload: { errorMsg },
	};
}

export function signOut() {
	return {
		type: SIGN_OUT,
	};
}

// REQUEST PASSWORD
export function requestPassword(cpf) {
	return {
		type: REQUEST_PASSWORD,
		payload: { cpf },
	};
}

export function requestPasswordSuccess(token) {
	return {
		type: REQUEST_PASSWORD_SUCCESS,
		payload: { token },
	};
}

// RESET PASSWORD
export function resetPassword(token, userId, password) {
	return {
		type: RESET_PASSWORD,
		payload: { token, userId, password },
	};
}

export function resetPasswordSuccess(response) {
	return {
		type: RESET_PASSWORD_SUCCESS,
		payload: { response },
	};
}
