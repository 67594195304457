import { envs } from 'store/windowServerData'

export const getThemePdv = async () => {
	localStorage.removeItem('themePdv')

	// const response = await fetch(`${envs.REACT_APP_GERMINI_API_URL}/Themes/Pdv`, {
	const response = await fetch(`${envs.REACT_APP_GERMINI_API_URL}/Themes/Pdv`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
		},
	})
	const data = await response.json()

	if (data) localStorage.setItem('themePdv', JSON.stringify(data))

	return data
}

export const CVALE = {
	images: {
		loginPage: {
			logo: {
				filename: 'logo.png',
				url: 'https://cvalefidelidadestorage.blob.core.windows.net/images/f9485d54-7717-4f2c-bfbd-d94dfb0bf212.png',
			},
			background: {
				filename: 'background.png',
				url: 'https://cvalefidelidadestorage.blob.core.windows.net/images/d1bc955a-237a-4055-99f2-b52355adea8c.jpg',
			},
		},
		internalPages: {
			headerLogo: {
				filename: 'logo_header.png',
				url: 'https://cvalefidelidadestorage.blob.core.windows.net/images/f9485d54-7717-4f2c-bfbd-d94dfb0bf212.png',
			},
		},
		favicon: {
			filename: 'favicon.png',
			url: 'https://cvalefidelidadestorage.blob.core.windows.net/images/945bc099-46be-44c8-85a3-81e435838447.png',
		},
	},
	navigation: {
		buttons: {
			textColor: '#FFFFFF',
			backgroundColor: '#21409A',
			elementsColor: '#21409A',
		},
		menu: {
			activeColor: '#74ccd4',
			backgroundColor: '#21409A',
			firstItemColor: '#647AB9',
			secondItemColor: '#4D66AE',
			thirdItemColor: '#3854A4',
			textColor: '#FFFFFF',
			hoverColor: '#3DBAC7',
		},
	},
	visual: { icons: { color: '#3DBAC7' } },
	titles: {
		main: { textColor: '#21409A' },
		secondary: { textColor: '#333333' },
		highlight: { textColor: '#21409A' },
	},
	header: { backgroundColor: '#FFFFFF', lineColor: '#3DBAC7' },
}

export const themeColor = () => {
	try {
		const theme = JSON.parse(localStorage.getItem('themePdv'))
		// return envs.REACT_APP_CVALE_IDENTITY === 'true' ? CVALE : theme
		return envs.REACT_APP_CVALE_IDENTITY === 'true' ? CVALE : theme
	} catch (error) {
		return [
			{
				images: {
					loginPage: {
						logo: {
							filename: 'logo.png',
							url: 'https://i.ibb.co/bR7G8nM/Gestao-Germini-logo-login.png',
						},
						background: {
							filename: 'background.png',
							url: 'https://i.ibb.co/7km0ctQ/GESTAO-back-login.jpg',
						},
					},
					internalPages: {
						headerLogo: {
							filename: 'logo_header.png',
							url: 'https://i.ibb.co/bR7G8nM/Gestao-Germini-logo-login.png',
						},
					},
					favicon: {
						filename: 'favicon.png',
						url: 'https://i.ibb.co/X5jV37c/favicon.png',
					},
				},
				navigation: {
					buttons: {
						textColor: '#FFFFFF',
						backgroundColor: '#F58B3C',
						elementsColor: '#1A9CAC',
					},
					menu: {
						activeColor: '#1A9CAC',
						backgroundColor: '#F58B3C',
						firstItemColor: '#F8AE77',
						secondItemColor: '#F7A263',
						thirdItemColor: '#F69750',
						textColor: '#FFFFFF',
						hoverColor: '#1A9CAC',
					},
				},
				visual: { icons: { color: '#1A9CAC' } },
				titles: {
					main: { textColor: '#08993A' },
					secondary: { textColor: '#333333' },
					highlight: { textColor: '#1A9CAC' },
				},
				header: { backgroundColor: '#FFFFFF', lineColor: '#F58B3C' },
			},
		]
	}
}

export const ColorLuminance = (hex, lum) => {
	hex = String(hex).replace(/[^0-9a-f]/gi, '')
	if (hex.length < 6) {
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
	}
	lum = lum || 0
	var rgb = '#',
		c,
		i
	for (i = 0; i < 3; i++) {
		c = parseInt(hex.substr(i * 2, 2), 16)
		c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
		rgb += ('00' + c).substr(c.length)
	}
	return rgb
}
