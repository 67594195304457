import produce from 'immer';
import {
	REQUEST_PASSWORD,
	REQUEST_PASSWORD_SUCCESS,
	RESET_PASSWORD,
	RESET_PASSWORD_SUCCESS,
	SELECT_PDV,
	SIGN_IN_FAILURE,
	SIGN_IN_REQUEST,
	SIGN_IN_SUCCESS,
	SIGN_IN_TOKEN,
	SIGN_OUT_REQUEST
} from './actions';

const INITIAL_STATE = {
	loading: false,
	logginFailed: false,
	errorMsg: '',
	pdv: null,
	profile: null,
	pdVs: null,
	partners: null,
	requestPasswordSuccess: null,
	requestingPassword: false,
	resetPasswordSuccess: null,
	resetingPassword: false,
	signed: false,
	token: null,
};

export default function auth(state = INITIAL_STATE, action) {
	return produce(state, (draft) => {
		switch (action.type) {
			case SIGN_IN_TOKEN: {
				draft.token = action.payload.token;
				break;
			}
			case SIGN_IN_REQUEST: {
				draft.loading = true;
				draft.logginFailed = false;
				draft.errorMsg = "";
				break;
			}
			case SELECT_PDV: {
				draft.pdv = action.payload.pdv;
				break;
			}
			case SIGN_IN_SUCCESS: {
				draft.profile = action.payload.user;
				draft.token = action.payload.token;
				draft.pdVs = action.payload.pdVs;
				draft.partners = action.payload.partners;
				draft.signed = true;
				draft.loading = false;
				draft.logginFailed = false;
				draft.errorMsg = "";
				break;
			}
			case SIGN_IN_FAILURE: {
				draft.signed = false;
				draft.loading = false;
				draft.logginFailed = true;
				draft.errorMsg = action.payload.errorMsg;
				break;
			}
			case SIGN_OUT_REQUEST: {
				return INITIAL_STATE;
			}
			case REQUEST_PASSWORD: {
				draft.requestingPassword = true;
				break;
			}
			case REQUEST_PASSWORD_SUCCESS: {
				draft.requestPasswordSuccess = action.payload.token;
				draft.requestingPassword = false;
				break;
			}

			case RESET_PASSWORD: {
				draft.resetingPassword = true;
				break;
			}
			case RESET_PASSWORD_SUCCESS: {
				draft.resetPasswordSuccess = action.payload.token;
				draft.resetingPassword = false;
				break;
			}

			default:
		}
	});
}
