import { createGlobalStyle } from 'styled-components';
import theme from 'assets/theme/CustomTheme';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    background: ${theme.background};
    color: ${theme.textColor};
    font-family: 'Roboto', 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased !important;
  }

  html,
  body,
  #root {
    height: 100vh;
	overflow: auto;
  }


  body, input, button {
    font-family: 'Roboto', 'Open Sans', sans-serif;
  }


  a {
    text-decoration: none;
  }


  ul {
    list-style: inside;
  }


  button {
    cursor: pointer;
  }

	.MuiTableCell-body {
    font-family: 'Roboto', 'Open Sans', sans-serif;
	}

	.MuiTableCell-head {
    font-family: 'Roboto', 'Open Sans', sans-serif;
	}
	.MuiButton-text span {
    font-family: 'Roboto', 'Open Sans', sans-serif;
	}
	.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters p {
    font-family: 'Roboto', 'Open Sans', sans-serif;
	}
	.PopoverPopupState-typography-96 {
    font-family: 'Roboto', 'Open Sans', sans-serif;
	}
  #alert-dialog-title {
    > h2 {

    display: flex;
    justify-content: space-between;
    align-items: center;
    }
  }
`
