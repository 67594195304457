/* eslint-disable no-undef */
import axios from 'axios'
import { envs } from 'store/windowServerData'

// const apiGateWayURLKernel = envs.REACT_APP_GERMINI_API_URL;
const apiGateWayURLKernel = envs.REACT_APP_GERMINI_API_URL

const apiKernel = axios.create({
	baseURL: `${apiGateWayURLKernel}`,
	headers: {
		appId: 'PDV1805',
		// 'Authorization': 'Bearer ' + localStorage.getItem('token')
	},
})

const authorize = (token) => {
	if (token) {
		apiKernel.defaults.headers.Authorization = `Bearer ${token}`
	}
}

const apiKernelNoAuth = axios.create({
	baseURL: `${apiGateWayURLKernel}`,
	headers: {
		appId: 'PDV1805',
		Authorization: 'Bearer ',
	},
})

const apiKernelPartner = axios.create({
	baseURL: `${apiGateWayURLKernel}`,
	headers: {
		appId: 'PRN1802',
		Authorization: 'Bearer ' + localStorage.getItem('token'),
	},
})

export { apiKernel, apiKernelPartner, apiKernelNoAuth, authorize }
