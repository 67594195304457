/* eslint-disable */
import React, { useState, createContext, useEffect } from 'react'
import { signOutRequest } from 'store/modules/auth/actions'
import { useDispatch } from 'react-redux'
import IdleTimer from 'components/SessionTimeOut'
import history from 'services/history'

import { apiKernel } from 'services/api'
import { envs } from 'store/windowServerData'
import { getGeneralData } from 'store/api/ProgramRules'

export const PageContext = createContext()

const PageContextProvider = (props) => {
	const [user, setUser] = useState()
	const [credit, setCredit] = useState()
	const [timerEnd, setTimerEnd] = useState(false)
	const dispatch = useDispatch()
	const [isTimeout, setIsTimeout] = useState(false)
	const [theme, setTheme] = useState([{}])

	const handlesignOut = () => {
		dispatch(signOutRequest())
	}



	useEffect(() => {
		if (history.location.pathname !== '/signin')  getGeneralData()
	}, [])

	useEffect(() => {
		const myInterval = setInterval(() => {
			apiKernel.post(`/PDV/UpdateLoginStatus`)
		}, 30000) //um minuto
		//ao desmontar componente, limpa intervalo
		return () => {
			clearInterval(myInterval)
		}
	}, [])

	//#region ===================== TIMER PARA EXPIRAR SESSAO ============================================================================================================
	useEffect(() => {
		const timer = new IdleTimer({
			// timeout: envs.REACT_APP_SESSION_TIMEOUT * 60, //expire after 30 seconds
			timeout: envs.REACT_APP_SESSION_TIMEOUT * 60, //expire after 30 seconds
			onTimeout: () => {
				setIsTimeout(true)
			},
			onExpired: () => {
				setIsTimeout(true)
			},
		})
		return () => {
			timer.cleanUp()
		}
	}, [isTimeout])

	useEffect(() => {
		if (isTimeout) {
			{
				history.location.pathname !== '/signin'
					? (handlesignOut(), setIsTimeout(false))
					: setIsTimeout(false)
			}
		}
	}, [isTimeout])

	//#endregion ===================== TIMER PARA EXPIRAR SESSAO ============================================================================================================

	return (
		<PageContext.Provider
			value={{
				user,
				setUser,
				credit,
				setCredit,
				timerEnd,
				setTimerEnd,
				isTimeout,
				setIsTimeout,
				theme,
				setTheme,
			}}
		>
			{props.children}
		</PageContext.Provider>
	)
}
export default PageContextProvider
