import axios from 'axios'
import i18n from 'i18next'
import jwt_decode from 'jwt-decode'
import { isEmpty, trim } from 'lodash'
import { takeLatest, takeEvery, call, put, all, delay } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import history from 'services/history'
import { apiKernel, apiKernelNoAuth, authorize } from 'services/api'

import {
	requestPasswordSuccess,
	resetPasswordSuccess,
	selectPDV,
	signFailure,
	signInSuccess,
	signOutRequest,
	REQUEST_PASSWORD,
	RESET_PASSWORD,
	SELECT_PDV,
	SIGN_IN_REQUEST,
	SIGN_IN_TOKEN,
	SIGN_OUT_REQUEST,
	SIGN_UP_REQUEST,
} from './actions'

import { LOGOUT_PDV, VALIDATE_LOGIN_PDV, LOGIN_PDV } from 'store/api/Endpoints/PDV'
import { getThemePdv } from 'store/api/Theme'
import { envs } from 'store/windowServerData'
import { getGeneralData } from 'store/api/ProgramRules'
//

export function* signIn({ payload }) {
	const { cpf, password } = payload
	const _cpf = cpf.replace(/[^\d]+/g, '')
	const _body = { username: _cpf, password }
	const _header = {
		accept: 'text/plain',
		'Content-Type': 'application/json',
		appId: 'PDV1805',
	}
	//
	const response = yield call(apiKernelNoAuth.post, VALIDATE_LOGIN_PDV, _body, _header)
	const { data } = response
	if (data.success === false && data.errors[0]?.messageCode === 43) {
		toast.error(i18n.t(data?.errors[0].message), {
			closeOnClick: true,
			autoClose: 5000,
		})
		return false
	}

	getGeneralData()

	if (data.success === true && !isEmpty(data.data.access_token)) {
		// CHECK IF THE USER HAS PERMISSION
		getThemePdv()
		if (isEmpty(data.data.pdVs) && isEmpty(data.data.partners)) {
			toast.error('Não é possível realizar o login, entre em contato com o administrador.', {
				closeOnClick: true,
				autoClose: 5000,
			})
			return false
		}
		//
		const user = {
			user: jwt_decode(data.data.access_token),
			token: data.data.access_token,
		}
		//
		if (user.token) {
			apiKernel.defaults.headers.common['Authorization'] = `Bearer ${user.token}`
			apiKernel.defaults.headers.common['appId'] = `PDV1805`
		}
		//
		localStorage.setItem('token', user.token)
		authorize(user.token)
		localStorage.setItem('partner', JSON.stringify(data.data.partners[0]))
		localStorage.setItem('user', JSON.stringify(user.user))
		localStorage.setItem('userIdentityId', JSON.stringify(user.user.sub))

		yield put(signInSuccess(user?.user, user.token, data.data.pdVs, data.data.partners))

		if (data.data.pdVs) {
			localStorage.setItem('PdvId', JSON.stringify(data.data.pdVs.key))
			yield call(
				apiKernel.post,
				`${LOGIN_PDV}?partnerPdvId=${data.data.pdVs.key}`,
				{},
				{
					headers: {
						Authorization: `Bearer ${data.data.access_token}`,
					},
				},
			)
		}
		//
		// CHECK IF THE USER ALREADY HAS PDV.
		if (!isEmpty(data.data.pdVs)) {
			yield put(selectPDV(data.data.pdVs))
		}
		history.push('/')
	} else {
		// toast.error('Falha na autenticação, verifique seus dados');
		console.log('Error Msg: ', data.errors)
		data.errors[0].message === 'invalid_credentials' &&
			toast.error('Não é possível realizar o login, entre em contato com o administrador.', {
				closeOnClick: true,
				autoClose: 5000,
			})
		//
		yield put(signFailure(data.errors))
		// history.push('/signin');
	}
}
//
const startLogoutPDV = () => {
	apiKernel.get(`${LOGOUT_PDV}`, {
		Authorization:
			'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6ImFjOGIyYWMyNDVlNGQyYmIzMDY4OTFkMjQ5NGEyNWRhIiwidHlwIjoiSldUIn0.eyJuYmYiOjE2MzM5NTU1NjQsImV4cCI6MTYzNDU2MDM2NCwiaXNzIjoiaHR0cHM6Ly92eHAtZ2VybWluaS1pZGVudGl0eS1kZXYuYXp1cmV3ZWJzaXRlcy5uZXQiLCJhdWQiOlsiaHR0cHM6Ly92eHAtZ2VybWluaS1pZGVudGl0eS1kZXYuYXp1cmV3ZWJzaXRlcy5uZXQvcmVzb3VyY2VzIiwiZ2VybWluaS1hcGkiXSwiY2xpZW50X2lkIjoicm8uY2xpZW50LnBhcnRuZXIucGR2Iiwic3ViIjoiZTQyNDc1MGUtY2UxYS00MTgxLTg5OWYtNjRkZDA2MDE1ZjRhIiwiYXV0aF90aW1lIjoxNjMzOTU1NTY0LCJpZHAiOiJsb2NhbCIsImVtYWlsIjoiZ2VvdmFuYS5zYW50b3NAdmV4cHJvLmNvbS5iciIsIm5hbWUiOiI4MTgxNDAyNTUzNyIsImdpdmVuX25hbWUiOiJMw7pjaWEgTmF0w6FsaWEgQmFwdGlzdGEiLCJzY29wZSI6WyJvcGVuaWQiLCJwcm9maWxlIiwiZ2VybWluaS1hcGkiXSwiYW1yIjpbInB3ZCJdfQ.CL-egfZ6ulvW6kl_ekMcmND9NZU8vHnxL69HhmjffBLOkux0dDGXVrb3bokKjxNR8EQ3VQ7DoEZHCuqCopezWYbdkixX2Y2BpI5T7XvDnzuFIRErNZn_Nif6kHbP-UKwCrtBDLiz42k7Us3YtOX8NXiyVqnEOIiKkWrS8UsmQ6ZliOXlV3cQ_PacvN19pfh_oK9Lc1Y_VlysQaEvjyhjpHUJrnGpZZ-g4T7k3BrYtvbwEt8QEYIpcfc35S7F7uj4iJu_4bHVVRTl45uQTGc_R4p8CwXYw7ixVDVSYKZqzNNbmtI_5pyuiFulEVNcc_1HMfa8-7uvxs0mzJKueVndLw',
	})
}
//
export function* signUp({ payload }) {
	try {
		const { name, email, password } = payload

		yield call(apiKernel.post, 'users', {
			name,
			email,
			password,
			provider: true,
		})

		history.push('/')
	} catch (error) {
		toast.error('Falha no cadastro, verifique seus dados!')
		yield put(signFailure())
	}
}

export function* requestPassword({ payload }) {
	// try {
	// 	const { cpf } = payload;
	// 	const response = yield call(apiKernel.get, `/PDV/SendResetPasswordRequest/${cpf}`);
	// 	//
	// 	if (response.data.sucess) {
	// 		toast.success(
	// 			'Requisição feita com sucesso, um e-mail foi enviado para você.'
	// 		);
	// 		history.push('/');
	// 	}
	// 	yield put(requestPasswordSuccess(response.data));
	// } catch (err) {
	// 	toast.error('Falha ao requisitar o reset da senha');
	// 	history.push('/');
	// }
}

export function* resetPassword({ payload }) {
	// try {
	// 	const headers = {
	// 		'x-api-key': 1,
	// 	};
	// 	const { token, userId, password } = payload;
	// 	const response = yield call(
	// 		apiKernel.post,
	// 		'Account/Password/Reset',
	// 		{ token, userId, newPassword: password },
	// 		{ headers }
	// 	);
	// 	if (response.data.succeeded) {
	// 		yield put(resetPasswordSuccess(response.data));
	// 		toast.success('Alteração de senha realizada com sucesso!');
	// 		history.push('/signin');
	// 	} else {
	// 		toast.error('Falha ao requisitar o reset da senha');
	// 	}
	// } catch (err) {
	// 	toast.error('Falha ao requisitar o reset da senha');
	// 	// history.push('/signin');
	// }
}

// Persistindo o token - F5
export function* setToken({ payload }) {
	try {
		delete apiKernel.defaults.headers.common['Authorization']
		let { token, profile, pdVs, partners } = payload && payload.auth
		//
		if (isEmpty(pdVs) && isEmpty(partners)) {
			// toast.info('Você não tem permissão de acesso ao PDV Virtual');
			// yield put(signOutRequest());
			return false
		}
		//
		axios.defaults.headers.common.Authorization = `bearer ${token}`
		// eslint-disable-next-line no-undef
		// axios.defaults.baseURL = envs.REACT_APP_GERMINI_API_URL
		axios.defaults.baseURL = envs.REACT_APP_GERMINI_API_URL
		apiKernel.defaults.headers.common['Authorization'] = `Bearer ${token}`
		//
		yield put(signInSuccess(profile, token, pdVs, partners))
	} catch (error) {
		// toast.error('Sessão expirada');
		console.log('Sessão expirada - Error -> ', error)
		// logout
		// yield put(signOutRequest());
	}
}

export function* signOut() {
	startLogoutPDV()
	localStorage.clear()
	history.push('/signout')
}

export function SelectPDV({ payload }) {
	put(selectPDV(payload.data))
	history.push('/receivement')
}

export default all([
	takeLatest('persist/REHYDRATE', setToken),
	takeLatest(SIGN_IN_REQUEST, signIn),
	takeLatest(SIGN_UP_REQUEST, signUp),
	takeLatest(SIGN_IN_TOKEN, setToken),
	takeLatest(SIGN_OUT_REQUEST, signOut),
	takeLatest(SELECT_PDV, SelectPDV),

	takeLatest(REQUEST_PASSWORD, requestPassword),
	takeLatest(RESET_PASSWORD, resetPassword),
])
